const Poloni = {utils: {}}

Poloni.utils = {
  select2_ajax_request: function() {
    // Use
    // Poloni.utils.select2_ajax_request("select_field_jquery", options = {})
    // Possible Options
    // {
    // allowClear: true|false, // default true
    // tags: true|false. // default false
    // multiple: function(){} | false, // default false
    // data_load: function(term){}, // default: q[:name_cont]
    // result: function(data){}, // default map text: item.name, id: item.id
    // init_selection: function(element, callback){}, // default find id seted in value fiel and get data from remote_url + /id.json and use result to map
    // }
    var pub = {};
    var priv = {};

    pub.init_all = function(selector, options){
      $(selector).each(function(){
        pub.init(this, options)
      });
    }

    pub.init = function(field,options) {
      priv.field = field;
      var _field = $(priv.field);

      priv.value_method = _field.data("value-method") === undefined ? "id" : _field.data("value-method");
      priv.text_method = _field.data("text-method") === undefined ? "name" : _field.data("text-method");
      priv.multiple = _field.data("multiple") === undefined ? false : _field.data("multiple");
      priv.remote_url = _field.data("url");
      priv.placeholder = _field.attr("placeholder");
      priv.root_elm = _field.data("root-element") === undefined ? false : _field.data("root-element") ;
      priv.create = _field.data("create") === undefined ? false : _field.data("create");
      priv.cont = _field.data("cont") === undefined ? true : _field.data("cont");
      priv.options = (options||{});
      priv.text_search_field = _field.data("cont") === undefined ? true : _field.data("cont");
      priv.extra_params = _field.data("params") === undefined ? {} : _field.data("params");

      if ( _field.data("text-search-field") === undefined ){
        priv.text_search_field = priv.cont ? priv.text_method+'_cont' : priv.text_method ;
      } else {
        priv.text_search_field = _field.data("text-search-field")
      }

      var select2_options = {
        minimumInputLength: (priv.options.minimumInputLength||2),
        placeholder: priv.placeholder,
        allowClear: (priv.options.allowClear||true),
        tokenSeparators: [',', ';'],
        createSearchChoice: (priv.create ? priv.create_search_choice : false),
        ajax: {
          url: priv.remote_url,
          dataType: 'json',
          quietMillis: 650,
          type: "GET",
          data: (priv.options.data_load || priv.data_load.bind({text_search_field: priv.text_search_field, extra_params: priv.extra_params  })),
          processResults: (priv.options.result || priv.result.bind({value_method: priv.value_method, text_method:  priv.text_method, options: priv.options, root_elm: priv.root_elm})),
        },
        // initSelection: (priv.options.init_selection || priv.init_selection.bind({field_select: priv.field, value_method: priv.value_method, text_method:  priv.text_method, options: priv.options, root_elm: priv.root_elm})),
      }

      if (priv.options.multiple || priv.multiple){
        select2_options['multiple'] = (priv.options.multiple || priv.multiple)
      }

      if (priv.options.tag){
        select2_options['tags'] = priv.options.tag
      }

      if (priv.options.formatResult){
        select2_options['formatResult'] = priv.options.formatResult
      }

      if (priv.options.formatSelection){
        select2_options['formatSelection'] = priv.options.formatSelection
      }

      if (priv.options.id){
        select2_options['id'] = priv.options.id
      }
      $(field).select2(select2_options);
    }

    priv.create_search_choice = function(term, data) {
      if ($(data).filter(function() {
        return this.text.localeCompare(term) ===   0;
      }).length === 0) {
        return {
          id: term,
          text: term
        };
      }
    }

    priv.data_load = function(term){
      var q = {}
      term[this.text_search_field] = term['term'];
      q['q'] = Object.assign(term, this.extra_params);
      return q
    }

    priv.result = function(data){
      var self = this;
      if (self.root_elm){ data = data[self.root_elm] }
      
      return {
        results: $.map(data.array, priv.map_json.bind(self))
      };
    }

    priv.map_json = function(item){
      if (this.options.map_json == undefined) {
        return {
          text: item[this.text_method],
          id: item[this.value_method]
        }
      } else {
        return this.options.map_json(item)
      }
    }

    return pub;
  }(),

  select2_only_init_with_ajax_request: function() {

    var pub = {};
    var priv = {};

    priv.options = {};

    pub.init = function(field,options) {
      priv.field = field;
      priv.remote_url = $(field).data("url-remote");
      priv.options = (options||{});

      $.ajax({
        url: priv.remote_url,
        dataType: 'json',
        type: "GET",
        success: function(data){
          $('#reports_query_filters').attr("data-filters", JSON.stringify(data.filters));
          obj = data.columns;
          $(field).select2({
            data: priv.result(obj).results,
            allowClear: (priv.options.allowClear||true),
            multiple:  true,
            tags: true,
            initSelection: priv.init_selection
          });
          // $(priv.field).attr("value", "");
        },
        error: function(data){
        }
      });
    }

    priv.result = function(data){
      return {
        results: $.map(data, priv.map_json)
      };
    }

    priv.map_json = function(item){
      if (priv.options.map_json == undefined) {
        return {
          text: item.value,
          id: item.id
        }
      } else {
        return priv.options.map_json(item)
      }
    }

    priv.init_remote_url = function(){
     if (priv.options.init_remote_url == undefined) {
        return (priv.remote_url + "/" + $(priv.field).data("id") +'.json')
      } else {
        return priv.options.init_remote_url(priv.remote_url)
      }
    }

     priv.init_selection = function(element, callback){

      var id = element.data("id");

      if (!id == "") {
        $.ajax({
          url: priv.init_remote_url(),
          dataType: 'json',
          type: "GET",
          success: function(data){
            callback(priv.result(data).results);
          },
          error: function(data){
          }
        });
      }else{
        var column_values = element.data("column-values");
        callback( $.map(column_values, priv.map_json) )
      }
    }

    return pub;
  }(),

  select2_only_init_with_ajax_request_generic: function() {

    var pub = {};
    var priv = {};

    priv.options = {};

    pub.init = function(field,options) {
      priv.field = field;
      priv.remote_url = $(field).data("url-remote");
      priv.options = (options||{});

      $.ajax({
        url: priv.remote_url,
        dataType: 'json',
        type: "GET",
        quietMillis: 650,
        success: function(data){
          $(field).select2({
            createSearchChoice: priv.create_search_choice,
            data: priv.result(data).results,
            allowClear: (priv.options.allowClear||true),
            multiple:  false,
            initSelection: priv.init_remote_url
          });
          $(priv.field).attr("value", "");
        },
        error: function(data){
        }
      });
    }

    priv.result = function(data){
      return {
        results: $.map(data, priv.map_json)
      };
    }

    priv.create_search_choice = function(term, data) {
      if ($(data).filter(function() {
        return this.text.localeCompare(term) ===   0;
      }).length === 0) {
        return {
          id: term,
          text: term
        };
      }
    }

    priv.map_json = function(item){
      if (priv.options.map_json == undefined) {
        return {
          text: item.value,
          id: item.id
        }
      } else {
        return priv.options.map_json(item)
      }
    }

    priv.init_remote_url = function(){
     if (priv.options.init_remote_url == undefined) {
        return (priv.remote_url + "/" + $(priv.field).data("id") +'.json')
      } else {
        return priv.options.init_remote_url(priv.remote_url)
      }
    }

    return pub;
  }(),
};

export default Poloni.utils
