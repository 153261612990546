const DarkMode = function(){
  var priv = {}
  var pub = {}

  priv.darkMode = ()=>{
    $('body').addClass('dark-mode');
    $('aside.main-sidebar').addClass('dark-mode');
    $('nav.main-header').removeClass('navbar-white').addClass('navbar-dark');
    $('#darkModeSwitch').attr('checked',true)
  }

  priv.defaultMode = ()=>{
    $('body').removeClass('dark-mode');
    $('aside.main-sidebar').removeClass('dark-mode');
    $('nav.main-header').removeClass('navbar-dark').addClass('navbar-white');
    $('#darkModeSwitch').attr('checked',false)
  }

  priv.setDarkMode = ()=>{
    localStorage.setItem('darkMode', true)
    priv.darkMode();
  }

  priv.unsetDarkMode = ()=>{
    localStorage.removeItem('darkMode')
    priv.defaultMode();
  }

  priv.toogle = ()=>{
    if (localStorage.getItem('darkMode') === 'true'){
      priv.unsetDarkMode();
    } else {
      priv.setDarkMode();
    }
  }

  pub.init = () => {
    $('#darkModeSwitchDiv').on('click', priv.toogle)

    if (localStorage.getItem('darkMode') === 'true'){
      priv.setDarkMode();
    }
  }

  return pub
}()

export default DarkMode
